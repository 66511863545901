import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactPage(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [emailDidEdit, setEmailDidEdit] = useState(false);
  const [sendIsLoading, setSendIsLoading] = useState(false);
  const emailIsinvalid = emailDidEdit && email !== "" && !email.includes("@");

  async function formSubmitHandler(event) {
    event.preventDefault();
    setSendIsLoading(true);
    emailjs
      .send(
        "service_wy1dmtg",
        "template_ugwacia",
        { fullName: name, message, senderEmail: email },
        "aNLg71BgIk2yf35Yt"
      )
      .then(
        function () {},
        function (error) {}
      )
      .finally(() => {
        setTimeout(() => {
          setSendIsLoading(false);
          clearForm();
        }, 2000);
      });
  }

  function clearForm() {
    setEmail("");
    setName("");
    setMessage("");
  }

  function handleOnChange(identifier, event) {
    const value = event.target.value;
    if (identifier === "email") {
      setEmail(value);
      setEmailDidEdit(false);
    } else if (identifier === "name") {
      setName(value);
    } else if (identifier === "message") {
      setMessage(value);
    }
  }

  function handlerBlurEmail() {
    setEmailDidEdit(true);
  }

  return (
    <div className="mt-5">
      <div className="container mt-5">
        <div className="pb-5 mt-5 pt-sm-5 p-md-5 row">
          <div className="col-md">
            <h3 className="mb-5 pt-5 mt-5">{props.data.contactHeader}</h3>
            <div>{props.data.letContactMessage}</div>
          </div>
          <div className="col-md">
            <form className="pt-5 pb-3" onSubmit={formSubmitHandler}>
              <input
                type="text"
                className="form-control mb-3"
                placeholder={props.data.name}
                value={name}
                onChange={(e) => handleOnChange("name", e)}
              />
              <input
                type="email"
                className={`${
                  emailIsinvalid && "border-danger "
                } form-control mb-3 `}
                name="senderEmail"
                placeholder={props.data.email}
                value={email}
                onChange={(e) => handleOnChange("email", e)}
                onBlur={handlerBlurEmail}
                required
              />
              <div className="d-flex pb-3">
                {emailIsinvalid && (
                  <span className="badge text-danger">
                    {props.data.emailInvalidText}
                  </span>
                )}
              </div>
              <textarea
                className="form-control mb-3 "
                name="message"
                placeholder={props.data.messageLabel}
                rows="4"
                cols="50"
                value={message}
                onChange={(e) => handleOnChange("message", e)}
              />
              <div className="d-flex">
                {sendIsLoading ? (
                  <button className="btn btn-secondary w-100" disabled="true">
                    <FontAwesomeIcon
                    icon={["fa", "spinner"]}
                    size="x"
                    className="mr-2"
                    spin
                  />
                    {props.data.send}

                  </button>
                ) : (<button
                  className="btn btn-secondary w-100"
                  disabled={emailIsinvalid}>
                  {props.data.send}
                </button>)
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
